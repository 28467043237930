@mixin min-xsm {@media (min-width: 480px) {@content;}}
@mixin max-xsm {@media (max-width: 480px - 1px) {@content;}}
@mixin min-sm {@media (min-width: 576px) {@content;}}
@mixin max-sm {@media (max-width: 576px - 1px) {@content;}}
@mixin min-md {@media(min-width: 768px) {@content;}}
@mixin max-md {@media (max-width: 768px - 1px) {@content;}}
@mixin min-lg {@media(min-width: 992px) {@content;}}
@mixin max-lg {@media (max-width: 992px - 1px) {@content;}}
@mixin min-xl {@media(min-width: 1200px) {@content;}}
@mixin max-xl {@media (max-width: 1200px - 1px) {@content;}}
@mixin min-xxl {@media(min-width: 1440px) {@content;}}
@mixin max-xxl {@media (max-width: 1440px - 1px) {@content;}}
@mixin min-fhd {@media(min-width: 1920px) {@content;}}
@mixin max-fhd {@media (max-width: 1920px - 1px) {@content;}}
@mixin min-rtn{@media (min-width: 2400px) {@content;}}
@mixin max-rtn {@media (max-width: 2400px - 1px) {@content;}}
@mixin min-4k {@media(min-width: 3840px) {@content;}}
@mixin max-4k {@media (max-width: 3840px - 1px) {@content;}}
@mixin min-md-max-lg {@media(min-width: 768px) and (max-width: 992px - 1px){@content;}}

