@import "variables";

.spacingTable {
    font-size: 14px;
    border-collapse: separate;
    table-layout: fixed;
    min-width: 100%;
    border-spacing: 0 5px;
    white-space: nowrap;

    thead,
    tbody {
        table-layout: fixed;
        width: 100%;
    }

    thead {
        border-spacing: 0 0;
    }

    tbody {
        tr {
            background-color: $white;

            &.newOne {
                background-color: $greenLight;
            }

            &.blueLight {
                background-color: $blueLight;
            }

            &.courseClosed {
                background-color: $courseClosed;
            }

            &.courseOpen {
                background-color: $courseOpen;
            }

            &.rejected {
                td {
                    position: relative;

                    &:before {
                        content: " ";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        border-bottom: 1px solid;
                        width: 100%;
                    }
                }
            }
        }
    }

    th {
        text-align: left;
        padding: 0 15px;
        color: $grey;
        font-weight: 500;
        text-transform: uppercase;
        white-space: break-spaces;
        position: sticky;
        top: 0;
        background: $greyVeryLight;
        z-index: 3;

        &.whiteSpaceUnset {
            white-space: unset;
        }

        &.staticColumn {
            z-index: 4;
        }
    }

    td {
        padding: 0 15px;
        height: 45px;

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }

        &:only-child {
            border-radius: 4px;
        }

        &.openNewTab {
            padding-right: 0;
            width: 0;

            > button {
                vertical-align: middle;
            }
        }

        &.staticColumn {
            background-color: white;
            position: sticky !important;
            z-index: 2;
        }
    }

    &--smallerRow {
        td {
            height: 40px;
        }
    }
}

.basicTable {
    font-size: 14px;
    min-width: 100%;
    border: 1px solid $grey;

    thead,
    tbody,
    tfoot {
        width: 100%;
        background-color: $white;
    }

    th {
        text-align: left;
        padding: 0 15px;
        color: $greenDark;
        font-weight: 500;
        text-transform: uppercase;
        white-space: break-spaces;
        font-size: 18px;

        &:not(:last-child) {
            border-right: 1px solid $grey;
        }

        &.lowerCase {
            text-transform: lowercase;
        }
    }

    tr {
        border-bottom: 1px solid $grey;
        height: 45px;

        &:first-child {
            border-top: 1px solid $grey;
        }

        &:last-child {
            border-bottom: unset;
        }
    }

    td {
        padding: 0 15px;
        border-right: 1px solid $grey;
    }
}
