@import "views";
@import "tables";
@import "buttons";
@import "inputs";
@import "labels";

body.modal-open,
body.menu-open {
    height: 100vh;
    overflow-y: hidden;
}
