@import "variables";
@import "mixins";

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: cover;
}

.basicList {
  &__header {
    margin-bottom: 1.5rem;
  }

  &__headerText {

  }

  &__headerAction {
  }

  &__filter {
    margin-bottom: 1.5rem;
  }
}

.listFilter {
  display: flex;
  flex-wrap: wrap;
  margin-top: -$listFilterMarginTop;
  position: relative;

  > button {
    margin-right: 1rem;
    margin-top: $listFilterMarginTop;
    max-width: 100%;
  }

  > input {
    flex: 1;
    margin-top: $listFilterMarginTop;

    @include min-md {
      margin-right: 1.5rem;
      width: 18rem;
      flex: unset;
    }
  }

  > span {
    margin-right: .5rem;
    margin-top: 15px;
    align-self: center;
  }
}

.headerWithButton {
  display: block;

  @include min-md {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
  }

  > label {
    font-size: 30px;
    color: $greenDark;
    word-break: break-word;
    margin-bottom: .5rem;
  }
}

.filterBox {
  position: absolute;
  background-color: $greenLight;
  top: calc(#{$basicHeight} + #{$listFilterMarginTop} + 10px);
  z-index: 4;
  border-radius: 10px;
  left: 15px;
  right: 15px;
  padding: 1rem 1rem 1rem 2rem;

  &--positionUnset {
    position: unset;
    padding: 1rem;
  }

  &__actions {
    text-align: end;

    > img {
      cursor: pointer;
    }
  }

  &__filters {

  }
}

.formText {
  margin-top: .5rem;

  &__label {
    color: $grey;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    word-wrap: break-word;

    &--extraBigInfo {
      font-size: 18px;
      font-weight: 500;
    }

    &--smallInfo {
      font-size: 12px;
    }
  }

  &__text {
    color: $black;
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    word-wrap: break-word;

    &--smallInfo {
      font-size: 13px;
    }
  }

  &--inlineBlock {
    .formText__label,
    .formText__text {
      width: unset;
    }

    .formText__label {
      margin-right: .5rem;
    }
  }
}

.formGroup {
  margin-bottom: .25rem;

  &--small {
    font-size: 12px !important;
  }

  &--big {
    font-size: 18px !important;
    font-weight: 500;
  }

  &--bold {
    font-weight: bold !important;
  }

  &--subText {
    font-weight: 400;
    margin-left: 1rem;
    color: $grey;
  }

  > label {
    margin-bottom: .25rem;
    color: $grey;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    word-wrap: break-word;
  }

  > input,
  > select,
  > textarea {
    display: block;
    width: 100%;
  }

  > i {
    display: none;
  }

  &.error {
    > label {
      color: $errorRed;
    }

    > input,
    > select {
      border-color: $errorRed;
    }

    > i {
      display: unset;
      color: $errorRed;
      font-size: 12px;
    }
  }

  &__range {
    > span {
      color: $grey;
      font-size: 14px;
      margin-right: .5rem;
    }

    > input {
      // 17px - span width, .5rem - span mr, .5rem - input mr
      width: calc((100% - (2 * (17px + .5rem + .5rem))) / 2);
      margin-right: .5rem;
    }
  }

  &__inputDelete {
    display: flex;
  }

  &--green {
    > label {
      color: $greenDark;
    }
  }
}

.basicForm {
  &__header {
    margin-bottom: 1.5rem;
  }

  &__subHeader {
    margin-bottom: 1.5rem;

    > label {
      font-size: 16px;
      color: $grey;
      display: block;
    }

    &--bigger {
      > label {
        font-size: 20px;
      }
    }
  }

  &__headerActions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -1rem;
    flex-flow: nowrap;

    button {
      margin-top: 1rem;

      &:not(:first-child) {
        @include min-md {
          margin-left: 1rem;
        }
      }
    }
  }

  &__form {
  }

  &__columns {
    justify-content: space-between;
  }

  &__firstColumn {

  }

  &__secondColumn {
    @include max-xl {
      margin-top: 1rem;
    }
  }
}

.fileWrapper {
  &__files {
    max-height: 13rem;
    height: 13rem;
    margin-top: -1rem;
    overflow-y: auto;

    > div {
      display: inline-block;
      margin-right: 1rem;
      margin-top: 1rem;
    }

    &--heightUnset {
      height: unset;
    }
  }
}

.modalBG {
  opacity: .75;
  background-color: $greyLight;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
}

.searchInputInput {
  position: relative;
}

.searchInputResult {
  position: absolute;
  width: 87.5%;
  background-color: $white;
  top: 45px;
  z-index: 1;

  &__wrapper {

  }

  &__records {

  }

  &__record {
    cursor: pointer;
    padding: 1rem;

    > label {
      cursor: pointer;
    }

    &:hover {
      background-color: $greyVeryLight;
    }
  }
}

.opacity05 {
  opacity: .5;
}

.lineThroughCalendar {
  .fc-event-title {
    text-decoration: line-through;
  }
}

.dragDropList {
  border: 1px solid $grey;
  min-height: 100%;
  background-color: $white;

  &__item {
    padding: 5px;
    cursor: grab;

    &:not(:last-child) {
      border-bottom: 1px solid $grey;
    }
  }
}

.basicUnorderedList {
  padding-left: 1rem;
  color: $grey;

  &--bigText {
    font-size: 18px;
    font-weight: 500;
  }

  &--smallText {
    font-size: 12px;
  }
}
