@import "variables";

.ng-select-container,
input,
select,
textarea {
    line-height: $basicHeight;
    border: 1px solid $greyMid;
    border-radius: 10px !important;
    padding-left: 0.8rem;
    background-color: $white;

    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
    }

    &:disabled {
        background-color: $greyVeryLight;
    }
}

.ng-select-container,
input,
select {
    height: $basicHeight !important;
}

.ng-select-container {
    padding-left: unset;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('/assets/images/down-arrow.svg');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 5px);
    background-position-y: 10px;
}

.basicInput {
    &__search {
        background-image: url("/assets/images/search.svg");
        background-repeat: no-repeat;
        background-position: left 8px center;
        padding-left: 2.2rem;
    }

    &--transparent {
        background-color: transparent;
    }
}

input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
    }
}
//.custom-control-input[disabled]~.custom-control-label::before

.custom-control {
    &.error {
        .custom-control-input {
            ~ .custom-control-label {
                &::before {
                    border-color: $errorRed;
                }
            }
        }

        > i {
            display: block;
        }
    }

    > i {
        display: none;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $greenDark;
                    background-color: $greenDark;
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    background-color: unset;
                }
            }
        }
    }

    .custom-control-label {
        line-height: 25px;

        &::before {
            box-shadow: none;
            outline: none;
        }
    }
}

.attachFile {
    width: 75px;
    position: relative;
    vertical-align: top;

    &:hover {
        .attachFile__delete {
            display: block;
        }
    }

    &__delete {
        display: none;
        position: absolute;
        top: -.7rem;
        right: -.7rem;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        text-align: center;
        color: $errorRed;
        background-color: $white;
        border: 1px solid $errorRed;
        cursor: pointer;
    }

    &__file {
        width: 100%;
        height: 75px;
        display: flex;
        justify-content: center;
        border: 1px dashed $greenDark;
        border-radius: 10px;
        background-color: $white;
        cursor: pointer;

        > img {
            width: 30px;
        }

        input[type="file"] {
            display: none;
        }

        &--greyBG {
            background-color: $greyLight;
        }

        &--attached {
            border: none;
        }

        &--chosen {
            background-color: $greenLight;
        }
    }

    &__fileName {
        font-size: 10px;
        word-break: break-word;
        margin-top: .5rem;
    }
}

.radioInput {
    &__input {
        display: inline-block;

        &:not(:first-child) {
            margin-left: 2rem;
        }

        > input {
            height: unset !important;
        }

        > label {
            margin-left: .5rem;
        }

        &--biggerText {
            > label {
                font-size: 16px;
            }
        }
    }
}
