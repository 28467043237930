$menuTopHeight: 80px;
$menuTopMobileHeight: 70px;
$menuLeftWidth: 16rem;
$basicHeight: 40px;
$basicHeight2: 45px;

// left & right main padding in view
$globalSidesPaddingMD: 3rem;
$globalSidesPaddingXL: 4rem;
$globalSidesPaddingFHD: 5rem;

$greyVeryLight: #F6F6F6;
$greyLight: #EBEBEB;
$greyMid: #CECECE;
$grey: #848383;
$greenLight: #C8E1C8;
$greenDark: #459244;
$orangeDark: #FABD03;
$verySoftBlue: #B6BDFC;
$verySoftRed: #FCC6B6;
$brightRed: #FD612F;
$white: #FFFFFF;
$black: #000000;
$errorRed: #DA0000;
$blueLight: #91C9F1;
$orangeBG: #FFA5002E;

$courseClosed: #71B9ED;
$courseOpen: #6BC969;

$listFilterMarginTop: 15px;
