@import "variables";

label {
    margin-bottom: unset;
    font-size: 14px;
    color: $grey;
}

a {
    color: $grey;
    text-decoration: underline;

    &:hover {
        color: $grey;
    }
}

i {
    &.error {
        font-size: 12px;
        color: $errorRed;
    }

    &.required {
        font-size: 14px;
        color: $errorRed;
    }
}

.cursorPointer {
    cursor: pointer;
}

.addNewEntry {
    color: $greenDark;
    display: inline-flex;
    cursor: pointer;

    &:before {
        content: '';
        background-image: url("/assets/images/plus-greenDark.svg");
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        line-height: 20px;
        margin-right: .5rem;
    }
}

.cGrey {
    color: $grey;
}

.cGreenDark {
    color: $greenDark;
}

.fs20 {
    font-size: 20px;
}

.fw600 {
    font-weight: 600;
}

.lineThrough {
    text-decoration: line-through !important;
}

.stationaryCourseInDashboard {
    .fc-event-main .fc-event-main-frame .fc-event-title-container .fc-event-title {
        color: yellow;

        &:before {
            content: "•";
            display: inherit;
            font-size: 25px;
            margin-right: .25rem;
            font-weight: bold;
            line-height: 0;
            vertical-align: sub;
        }
    }
}

.inProgressCourseInDashboard {
    .fc-event-main .fc-event-main-frame .fc-event-title-container .fc-event-title {
        color: red;
    }
}
