@import "variables";
@import "mixins";

.basicButton {
    height: $basicHeight2;
    border-radius: 10px;
    border: none;
    padding: 0 1rem;
    white-space: nowrap;

    &:hover {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }

    &__greenDark {
        //background-color: $greenDark;
        background-color: $orangeDark;
        color: $white;
    }

    &__greenLight {
        background-color: $greenLight;
        color: $greenDark;
    }

    &__icon {
        background-position: center;
        background-repeat: no-repeat;
        padding: 0 1.4rem;

        &--filter {
            background-image: url('/assets/images/filter-green.svg');
        }

        &--delete {
            background-image: url('/assets/images/delete-greyMid.svg');
        }

        &--openNewTab {
            background-image: url('/assets/images/plus.svg');
        }
    }

    &__transparent {
        background-color: transparent;

        &:hover {
            box-shadow: none;
        }
    }

    &--disabled,
    &:disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    &--h32 {
        height: 32px;
    }
}

.buttonActions {
    @include max-md {
        button {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    @include min-md {
        text-align: right;

        button {
            margin-left: 1rem;
        }
    }
}
